@use '../../styles/base/settings' as *;

//mobile
.container {
  width: 100%;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 15px;
}
.cardContainer {
  margin: 25px 10px;
  width: 100%;
  max-width: 400px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  border: 2px solid #ecf0f1;
  background-color: #ffffff;
}
.text1 {
  color: #17202a;
  font-family: 'Inter-Light';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  //
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.pointsContainer {
  margin-left: 18px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
